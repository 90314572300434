<template>
  <k-field-group language-prefix="user.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-container class="pa-0">
          <v-row class="fill-height">
            <v-col cols="6">
              <form-sheet class="fill-height">
                <KTextField v-model="values.firstName" field="firstName" required/>
                <KTextField v-model="values.insertion" field="insertion"/>
                <KTextField v-model="values.lastName" field="lastName" required/>
                <KTextField v-model="values.function" field="function"/>
                <KTextField v-model="values.phone" field="phone"/>
                <KTextField v-model="values.email" field="email" required/>
                <StoreAutocomplete v-model="values.storeOwner" field="stores" multiple/>
                <KCheckbox v-model="values.isAdmin" field="isAdmin"/>
                <KCheckbox v-model="values.isActive" field="isActive"/>
              </form-sheet>
            </v-col>
            <v-col cols="6">
              <form-sheet class="fill-height">
                <KTextField v-model="values.company" field="company"/>
                <KSelect v-model="values.language.locale" :items="locales" field="locale" required/>
              </form-sheet>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { locales } from '@/application/enums/locale.js';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import StoreAutocomplete from '@/modules/store/components/StoreAutocomplete.vue';

export default {
  name: 'UserForm',
  components: {
    StoreAutocomplete,
    KSelect,
    KCheckbox,
    FormSheet,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
    locales() {
      return Object.values(locales).map(locale => ({
        text: this.$t(`locale.${locale}`),
        value: locale,
      }));
    },
  },
};
</script>
