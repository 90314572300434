export const locales = {
  GERMAN: 'de-DE',
  ENGLISH: 'en-GB',
  SPANISH: 'es-ES',
  FRENCH: 'fr-FR',
  ITALIAN: 'it-IT',
  DUTCH: 'nl-NL',
  POLISH: 'pl-PL',
  SWEDISH: 'sv-SE'
}
