<template>
  <div>
    <k-crud-layout :search.sync="searchQuery" :filters.sync="filters">>
      <template #header>{{ $t('user.pageTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile @click="handleOpenCreateDialog">
          {{ $t('user.create') }}
        </v-btn>
        <UserForm v-model="createDialog"
                  :request="createRequest"
                  :title="$t('user.create')"
                  :values="createFormValues"
                  @change="$refs.table.reload()"/>
      </template>
      <template #filters="{attrs, on}">
        <UsersFilter
            v-bind="attrs"
            v-on="on"
        />
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      :params="filters"
                      language-prefix="user.fields"
                      resource="user"
                      @click:edit="openUpdate"
                      @click:row="openUpdate">
          <template #item.isActive="{item: {isActive}}">
            <v-icon v-if="!isActive">$disabled</v-icon>
          </template>
          <template #item.isAdmin="{item: {isAdmin}}">
            <v-icon v-if="isAdmin">$enabled</v-icon>
          </template>
        </k-crud-table>
      </template>

    </k-crud-layout>

    <UserForm v-model="updateDialog"
              :request="updateRequest"
              :title="$t('user.update')"
              :values="updateFormValues"
              is-update-form
              @change="$refs.table.reload()"/>
  </div>
</template>

<script lang="js">
import eventBus from '@/application/eventBus.js';
import { User } from '@/application/models/User.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/user/api';
import UserForm from '@/modules/user/components/UserForm.vue';
import { mapGetters } from 'vuex';
import UsersFilter from '@/modules/user/components/UsersFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'UserResource',
  mixins: [querySyncMixin],
  components: {
    UsersFilter,
    UserForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
      createDialog: false,
      createFormValues: new User(),
      dialogOpen: false,
      updateDialog: false,
      updateFormValues: new User(),
    };
  },
  computed: {
    ...mapGetters('settings', { currentClientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'isActive',
          language: 'isActive',
          align: 'center',
        },
        {
          value: 'isAdmin',
          language: 'isAdmin',
          align: 'center',
        },
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'company',
          language: 'company',
        },
        {
          value: 'email',
          language: 'email',
        },
        {
          value: 'function',
          language: 'function',
        },
        {
          value: 'phone',
          language: 'phone',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'user.index' },
        text: this.$t('user.pageTitle'),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new User();
      this.createDialog = true;
    },
    createRequest() {
      return create(this.currentClientId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    indexRequest(...args) {
      return index(this.currentClientId, ...args);
    },
    async openUpdate(item) {
      this.updateFormValues = new User();
      const showRequest = await show(item.id);
      this.updateFormValues.mapResponse(showRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
