<template>
  <k-field-group language-prefix="user.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('actions.applyFilters')"
               :title="$t('user.filterTitle')"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <KSelect v-model="form.isAdmin"
               :items="booleanItems"
               :clearable="true"
               field="isAdmin"
               class="mb-2 pb-1"
      />
      <KSelect v-model="form.isActive"
               :items="booleanItems"
               :clearable="true"
               field="isActive"
               class="mb-2 pb-1"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';

export default {
  name: 'UsersFilter',
  inheritAttrs: false,
  components: {
    KSidebar,
    KFieldGroup,
    KSelect
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data: (vm) => ({
    form: {},
    booleanItems: [
      {
        text: vm.$t('global.boolean.yes'),
        value: 1,
      },
      {
        text: vm.$t('global.boolean.no'),
        value: 0,
      },
    ],
  }),
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.cleanFilters(this.form);
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    cleanFilters(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
